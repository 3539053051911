<template>
  <div class="contact-container">
    <vue-element-loading :active="loading" is-full-screen>
      <img
        src="@/assets/gif/Motion-Logo-.gif"
        style="width: 50%; max-width: 250px"
      />
    </vue-element-loading>
    <Navbar @lang="getLang" color="white" />

    <div class="main-content">
      <div style="display: none">
        PT HIGO FITUR INDONESIA Beri tahu apa yang bisa kami bantu Plaza Kedoya
        Elok, Lantai 3 Blok DA No. 3-4, Jakarta Barat 11520 PT HIGO FITUR
        INDONESIA Beri tahu apa yang bisa kami bantu Plaza Kedoya Elok, Lantai 3
        Blok DA No. 3-4, Jakarta Barat 11520 PT HIGO FITUR INDONESIA Beri tahu
        apa yang bisa kami bantu Plaza Kedoya Elok, Lantai 3 Blok DA No. 3-4,
        Jakarta Barat 11520 PT HIGO FITUR INDONESIA Beri tahu apa yang bisa kami
        bantu Plaza Kedoya Elok, Lantai 3 Blok DA No. 3-4, Jakarta Barat 11520
        PT HIGO FITUR INDONESIA Beri tahu apa yang bisa kami bantu Plaza Kedoya
        Elok, Lantai 3 Blok DA No. 3-4, Jakarta Barat 11520 PT HIGO FITUR
        INDONESIA Beri tahu apa yang bisa kami bantu Plaza Kedoya Elok, Lantai 3
        Blok DA No. 3-4, Jakarta Barat 11520 PT HIGO FITUR INDONESIA Beri tahu
        apa yang bisa kami bantu Plaza Kedoya Elok, Lantai 3 Blok DA No. 3-4,
        Jakarta Barat 11520 PT HIGO FITUR INDONESIA Beri tahu apa yang bisa kami
        bantu Plaza Kedoya Elok, Lantai 3 Blok DA No. 3-4, Jakarta Barat 11520
        PT HIGO FITUR INDONESIA Beri tahu apa yang bisa kami bantu Plaza Kedoya
        Elok, Lantai 3 Blok DA No. 3-4, Jakarta Barat 11520 PT HIGO FITUR
        INDONESIA Beri tahu apa yang bisa kami bantu Plaza Kedoya Elok, Lantai 3
        Blok DA No. 3-4, Jakarta Barat 11520 PT HIGO FITUR INDONESIA Beri tahu
        apa yang bisa kami bantu Plaza Kedoya Elok, Lantai 3 Blok DA No. 3-4,
        Jakarta Barat 11520
      </div>

      <div class="first-content">
        <div v-if="lang === 'id'" class="text-top">
          <h1>Hubungi Kami</h1>
          <p>Seneng bisa bantu kamu</p>
        </div>
        <div v-else class="text-top">
          <!-- because duplicate title tag (SEO) -->
          <h6 style="font-size: 32px">Contact Us</h6>
          <p>Happy to help you</p>
        </div>
        <div
          id="contact-min"
          style="position: relative; margin: 2rem auto; padding"
        >
          <img
            @load="imgLoad"
            src="@/assets/contact/Group 307-min.png"
            alt="contact"
            style="width: 100%; height: auto"
          />

          <img
            @load="imgLoad"
            src="@/assets/contact/Group 306-min.png"
            alt="contact"
            style="
              width: 84%;
              height: auto;
              position: absolute;
              left: 2.5rem;
              top: -2vh;
            "
            id="rotate-contact"
          />
        </div>
        <div class="location-contact" v-if="windowWidth > 767">
          <div
            class="location"
            style="display: grid; grid-template-rows: 0.8fr 1fr"
          >
            <img
              @load="imgLoad"
              src="@/assets/contact/placeholder-min.png"
              alt
            />
            <p style="font-family: 'Arial', sans-serif; font-size: 0, 8rem">
              PT HIGO FITUR INDONESIA
              <br />Plaza Kedoya Elok, Lantai 3 <br />Blok DA No. 3-4, Jakarta
              Barat <br />11520
            </p>
          </div>
          <div
            class="contact"
            style="display: grid; grid-template-rows: 0.8fr 1fr"
          >
            <img @load="imgLoad" src="@/assets/contact/Group 174-min.png" alt />

            <p style="font-family: 'Arial', sans-serif; font-size: 0, 8rem">
              info@higo.id
              <br />(021) 5806860
            </p>
          </div>
        </div>
      </div>
      <div class="second-content">
        <div class="form-text top">
          <p v-if="lang === 'id'">Beri tahu apa yang bisa kami bantu</p>
          <p v-else>Tell us what can we help you with</p>
        </div>

        <form class="contact-form">
          <div class="form-item name">
            <input
              type="text"
              :placeholder="lang === 'id' ? 'Nama' : 'Name'"
              v-model="name"
            />
            <p v-if="nameReq">{{ nameReq }}</p>
          </div>
          <div class="form-item email">
            <input type="email" v-model="email" placeholder="E-mail" />
            <p v-if="emailReq">{{ emailReq }}</p>
            <p v-if="emailInvalid">{{ emailInvalid }}</p>
          </div>
          <div class="form-item telepon">
            <input
              type="tel"
              v-model="tlp"
              v-on:keypress="blockNonNumericInput"
              :placeholder="
                lang === 'id' ? 'Nomor telepon (+62888xxx) ' : 'Phone Number'
              "
            />
            <p v-if="tlpReq">{{ tlpReq }}</p>
          </div>

          <div
            class="form-item"
            style="
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-column-gap: 2%;
            "
          >
            <div id="select-option">
              <div id="select">
                <select
                  id="select-btn"
                  v-model="selected"
                  style="
                    padding: 0 1rem;
                    width: 96%;
                    height: 100%;
                    border-radius: 20px;
                    border: 1px solid silver;
                    font-size: 0.9rem;
                    height: 2rem;
                  "
                >
                  <option disabled value="">
                    {{ lang === "id" ? "Kategori" : "Category" }}
                  </option>
                  <option>
                    {{
                      lang === "id" ? "Wifi Advertising" : "Wifi Advertising"
                    }}
                  </option>
                  <option>
                    {{ lang === "id" ? "Higospot" : "Higospot" }}
                  </option>
                  <option>
                    {{
                      lang === "id"
                        ? "Integrated Digital Agency"
                        : "Integrated Digital Agency"
                    }}
                  </option>
                </select>
                <img
                  @click.prevent="selectChange"
                  src="@/assets/Path 389.png"
                  alt="arrow-down"
                  id="arrow-pick"
                />
              </div>
            </div>
            <div id="company">
              <input
                type="text"
                v-model="company"
                :placeholder="
                  lang === 'id'
                    ? 'Nama Usaha, Lokasi, Event, Brand'
                    : 'Name, Location, Event, Brand'
                "
              />
              <p v-if="companyReq">{{ companyReq }}</p>
            </div>
          </div>

          <div class="form-item-area comment">
            <textarea
              name
              id="comment"
              cols="30"
              rows="30"
              :placeholder="lang === 'id' ? 'Komentar...' : 'Comments...'"
              v-model="comment"
              style="font-size: 12pt"
            ></textarea>
            <p v-if="commentReq">{{ commentReq }}</p>
          </div>

          <div class="btn-form" v-if="!loadingBtn">
            <button @click.prevent="sendContact">
              {{ lang === "id" ? "Kirim" : "Send" }}
            </button>
          </div>

          <div v-else class="btn-form">
            <vue-element-loading
              :active="loadingBtn"
              spinner="spinner"
              color="#FFF"
            />
          </div>
        </form>
      </div>
    </div>

    <div class="location-contact" v-if="windowWidth <= 767">
      <div
        class="location"
        style="
          display: grid;
          grid-template-rows: 100%;
          grid-template-columns: 0.5fr 1fr;
          width: 100%;
          place-items: center;
        "
      >
        <img @load="imgLoad" src="@/assets/contact/placeholder-min.png" alt />
        <p style="font-family: 'Arial', sans-serif; font-size: 0, 8rem">
          PT HIGO FITUR INDONESIA
          <br />Plaza Kedoya Elok, Lantai 3 <br />Blok DA No. 3-4, Jakarta Barat
          <br />11520
        </p>
      </div>
      <div
        class="contact"
        style="
          display: grid;
          grid-template-rows: 100%;
          grid-template-columns: 0.5fr 1fr;
          width: 100%;
          place-items: center;
        "
      >
        <img @load="imgLoad" src="@/assets/contact/Group 174-min.png" alt />

        <p style="font-family: 'Arial', sans-serif; font-size: 0, 8rem">
          info@higo.id
          <br />(021) 5806860
        </p>
      </div>
    </div>

    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";

import axios from "axios";

const baseUrl = "https://apiw.higo.id/web-savecontactus";
export default {
  components: {
    Navbar,
    Footer,
  },
  data: function () {
    return {
      lang: "",
      loadingBtn: false,
      loading: true,
      imgCount: 4,
      errors: [],
      nameReq: null,
      emailReq: null,
      emailInvalid: null,
      companyReq: null,
      commentReq: null,
      tlpReq: null,
      countryReq: null,
      typeReq: null,
      name: "",
      email: "",
      company: "",
      comment: "",
      tlp: "",
      country: "",
      type: "",
      typeOptions: [
        "Restaurant",
        "Cafe",
        "Bar/Lounge",
        "Public Area",
        "Events/Festival",
      ],
      windowWidth: "",
      selected: "",
    };
  },
  metaInfo: {
    title: "HIGO | Hubungi kami",
    meta: [
      {
        name: "description",
        content:
          "Tak perlu sungkan hubungi kami dan tanya jadi lokasi partner atau beriklan di HIGOspot.",
      },
      {
        name: "keywords",
        content:
          "Lokasi Internet Gratis, Spot Internet Gratis, Iklan di HIGOspot, Cara gabung jadi HIGOspot",
      },
    ],
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    });
  },
  created() {
    window.scrollTo(0, 0);
    this.windowWidth = window.innerWidth;
  },
  methods: {
    blockNonNumericInput(evt) {
      const input = evt.key;
      if (/[^+0-9\s]+/.test(input)) {
        this.tlpReq = "*Isi nomor telpon kamu dengan format yang benar";
        evt.preventDefault();
      }
    },
    getLang(value) {
      this.lang = value;
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 550);
    },
    imgLoad() {
      this.imgCount--;
      if (!this.imgCount) {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
    checkEmail(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkPhone(str) {
      const re = /^(^\+62\s?|^0)(\d{3,4}-?){2}\d{3,4}$/;
      console.info("re.test(str)", re.test(str), str);
      return re.test(str);
    },
    sendContact() {
      this.loadingBtn = true;

      this.errors = [];
      this.nameReq = null;
      this.emailReq = null;
      this.emailInvalid = null;
      this.companyReq = null;
      this.commentReq = null;
      this.tlpReq = null;

      if (this.name === "") {
        this.errors.push("Name is required");
        this.nameReq = "*Isi nama kamu";
      }

      if (this.email === "") {
        this.errors.push("Email is required");
        this.emailReq = "*Isi email kamu";
      } else {
        if (!this.checkEmail(this.email)) {
          this.errors.push("Email is invalid");
          this.emailInvalid = "*Isi dengan email yang valid";
        }
      }

      if (this.company === "") {
        this.errors.push("Company is required");
        this.companyReq = "*Isi nama usaha kamu";
      }
      // comment: "",
      if (this.comment === "") {
        this.errors.push("Comment is required");
        this.commentReq = "* Isi dengan pesan kamu";
      }
      // tlp: "",
      if (this.tlp === "") {
        this.errors.push("Phone number is required");
        this.tlpReq = "*Isi dengan nomor telepon kamu";
      } else {
        if (!this.checkPhone(this.tlp)) {
          this.errors.push("Phone number is required");
          this.tlpReq = "*Isi dengan nomor telepon kamu";
        }
      }
      // country: "",
      // if (this.country === '') {
      //   this.errors.push('Country is required')
      // }
      // type: "",
      this.type = this.selected;

      if (!this.errors[0]) {
        let formData = new FormData();

        formData.append("email", this.email);
        formData.append("name", this.name);
        formData.append("phone", this.tlp);
        formData.append("company", this.company);
        formData.append("merchant_type", this.type);
        formData.append("comments", this.comment);
        formData.append(
          "type",
          sessionStorage.getItem("from-url-contact") ? "Home" : "Contact Us"
        );
        formData.append(
          "from_url",
          sessionStorage.getItem("from-url-contact")
            ? sessionStorage.getItem("from-url-contact")
            : "https://higo.id/contact-us"
        );

        axios({
          method: "POST",
          url: baseUrl,
          data: formData,
        })
          .then(({ data }) => {
            this.loadingBtn = false;
            this.resetForm();
            if (data.success) {
              this.$toast.open({
                message: `Success\nThanks for Submitting`,
                type: "success",
                position: "bottom-right",
                duration: 2500,
                dismissible: true,
              });
            } else {
              this.$toast.open({
                message: data.message,
                type: "warning",
                position: "bottom-right",
                duration: 2500,
                dismissible: true,
              });
            }
          })
          .catch((err) => {
            this.resetForm();
          });
      } else {
        this.loadingBtn = false;
      }
    },
    resetForm() {
      this.tlp = "";
      this.company = "";
      this.comment = "";
      this.country = "";
      this.type = "";
      this.name = "";
      this.email = "";
      this.tlpReq = null;
      this.nameReq = null;
      this.emailReq = null;
      this.companyReq = null;
      this.countryReq = null;
      this.typeReq = null;
      this.commentReq = null;
      this.loadingBtn = false;
    },
  },
};
</script>

<style>
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.contact-container {
  width: 100%;
  min-height: 100vh;
}

.contact-container .main-content {
  min-height: 100vh;
  padding: 3rem 5rem 0 5rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.contact-container .first-content {
  min-height: calc(100vh - 3rem);
  max-height: calc(100vh - 3rem);
  padding: 2rem 5rem;
  display: grid;
  grid-template-rows: 1fr 3fr 1fr;
}

.contact-container .top {
  align-self: end;
}

.contact-container .first-content .text h1 {
  font-size: 1rem;
  color: black;
}

.contact-container .first-content .text-top p {
  font-size: 0.9rem;
  color: #0245ab;
}

.contact-container .second-content {
  padding: 2.8rem 2rem;
  height: 100%;
  display: grid;
  place-items: center;
}

.form-text p {
  font-size: 1.2rem;
}

form {
  width: 100%;
}

.form-item,
.form-item-area {
  margin: 5% auto;
  width: 100%;
}

.form-item p,
.form-item-area p {
  color: red;
  font-size: 11px;
  margin-left: 10px;
  margin-top: 2px;
}

.form-item input,
.vs__dropdown-toggle {
  width: 100%;
  height: 2rem;
  padding: 0 1rem;
  font-size: 0.9rem;
  border: 1px solid silver;
  border-radius: 25px;
  outline: none;
}

textarea {
  font-size: 2rem;
}

label,
input,
textarea {
  font-family: "Ariarl", sans-serif;
}

.vs__search {
  border: none !important;
  height: 100% !important;
}

.vs__selected-options {
  display: flex;
  align-items: center;
}

.vs__selected {
  height: 100%;
  margin: 0;
}

#comment {
  width: 100%;
  height: 7rem;
  text-align: left;
  border: 1px solid silver;
  border-radius: 25px;
  font-size: 1rem;
  padding: 10px 20px;
  resize: none;
  outline: none;
}

.btn-form {
  display: flex;
  justify-content: flex-end;
}

.btn-form button {
  height: 2.5rem;
  width: 40%;
  font-size: 1.2rem;
  color: white;
  background-color: #0245ab;
  border-radius: 25px;
  border: none;

  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.btn-form button:hover {
  cursor: pointer;
  background-color: #f9ca45;
  color: #0245ab;
}

#contact-min {
  width: 70%;
  height: auto;
}

.location-contact {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  grid-column-gap: 20px;
}

.location .contacts {
  display: grid;
  grid-template-rows: 1fr 2fr;
}

.location p,
.contact p {
  font-size: 0.8rem;
  font-family: "Arial", sans-serif;
  line-height: 1.5;
}

#rotate-contact {
  z-index: -2;
  animation: rotation 5.5s infinite linear;
  -webkit-animation: rotation 5.5s infinite linear;
}

select {
  /* // A reset of styles, including removing the default dropdown arrow */
  appearance: none;
  /* // Additional resets for further consistency */
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  cursor: pointer;
}

#select {
  width: 100%;
  height: 100%;
  position: relative;
}

#arrow-pick {
  width: 0.6rem;
  transform: rotate(270deg);
  position: absolute;
  right: 15%;
  top: 0.5rem;
  z-index: -1;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .contact-container .main-content {
    padding: 3rem 2rem 0 2rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .contact-container .main-content {
    padding: 3rem 1.5rem 0 1.5rem;
  }

  .contact-container .first-content {
    padding: 2rem 2rem;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .contact-container .main-content {
    padding: 3rem 5% 0 5%;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
  }

  .contact-container .first-content {
    grid-template-rows: 1fr 3fr;
    height: 400px;
    max-height: unset;
    min-height: 400px;
  }

  .location-contact {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 20px;
    width: 100%;
    place-items: center;
    padding: 0 10%;
    /* place-items: cx; */
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 1rem;
    margin: 3rem 0;
  }

  .location,
  .contact {
    grid-template-columns: auto 1fr !important;
    grid-column-gap: 8%;
    place-items: start end !important;
  }

  .location p,
  .contact p {
    justify-self: baseline;
  }
}

@media (max-width: 575.98px) {
  .contact-container .main-content {
    padding: 3rem 5% 0 5%;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
  }

  .contact-container .first-content {
    grid-template-rows: 1fr 3fr;
    height: 400px;
    max-height: unset;
    min-height: 400px;
    padding: 2rem 5% 1rem 5%;
  }

  .contact-container .second-content {
    padding: 1rem 5%;
  }

  .location-contact {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 20px;
    width: 100%;
    place-items: center;
    padding: 0 10%;
    /* place-items: cx; */
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 1rem;
    margin: 3rem 0;
  }
  .location,
  .contact {
    grid-template-columns: auto 1fr !important;
    grid-column-gap: 8%;
    place-items: start end !important;
  }

  .location p,
  .contact p {
    justify-self: baseline;
  }

  .form-item,
  .form-item-area {
    width: 100%;
  }

  /* .location p .contact {

  } */
}
</style>
